body {
  font: 14px "Helvetica", Futura, sans-serif;
  margin: 20px;
}

.App {
  max-width: 700px;
}

code {
  background-color: rgba(222, 222, 222, 0.3);
  color: crimson;
}
